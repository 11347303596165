import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "home",
    component: () => import("../views/login/LoginIndex.vue"),
  },
  {
    path: "/",
    name: "main",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MainIndex.vue"),
    redirect: "/infoManager",
    children: [
      {
        path: "/infoManager",
        name: "infoManager",
        component: () => import("../views/InfoManager/InfoManagerIndex.vue"),
        redirect: "/infoManager/pushAddressManager",
        children: [
          {
            path: "pushAddressManager",
            name: "pushAddressManager",
            component: () =>
              import("../views/InfoManager/PushAddressManager.vue"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
